import { useRouter } from "next/router";

import { getFac } from "~/lib/client/fac";
import useDeviceDetect from "~/lib/client/hooks/useDeviceDetect";
import { useGraphqlClient } from "~/lib/client/hooks/useGraphqlClient";
import { PageName } from "~/lib/client/logger/base";
import { SendActivityLogInput } from "~/lib/graphql/client";
import { useAppContext } from "~/pages/_app";

/**
 * ActionName で使う語彙一覧(随時追加)
 * - show
 * - click
 *
 * [note]
 * ログできるパラメータは SendActivityLogInput から useActivityLogger で詰めるパラメータ以外を指す
 * ここで使うパラメータを明示するよりも、使えるログは型推論に任せて使用側でパラメータを指定する方が良いと思った
 */
export type LoggableParams = Omit<
  SendActivityLogInput["item"],
  | "clientMutationId"
  | "eventUnixTime"
  | "ipAddress"
  | "path"
  | "userAgent"
  | "userId"
  | "userName"
  | "pageName"
  | "keyword"
> & { pageName: PageName };

export const useActivityLogger = ():
  | ((params: LoggableParams) => Promise<void>)
  | undefined => {
  const router = useRouter();
  const { currentUser } = useAppContext();
  const { userAgent } = useDeviceDetect();
  const graphqlClient = useGraphqlClient();

  if (typeof window === "undefined") return;
  if (!userAgent) return;
  if (!graphqlClient) return;
  if (currentUser === undefined) return;

  return async (params: LoggableParams) => {
    const fac = getFac();
    const { keyword } = router.query;
    const item: SendActivityLogInput["item"] = {
      ...params,
      userId: currentUser?.id,
      userAgent,
      path: router.pathname,
      userName: currentUser?.name,
      eventUnixTime: Math.floor(Date.now() / 1000),
      fac,
      keyword: !keyword ? undefined : String(keyword),
    };

    try {
      await graphqlClient.sendActivityLog(item);
    } catch (e) {
      // Firebase User が先に作られて、 Fussy User が後に作られると、ログが送れない。
      console.error(e);
    }
  };
};

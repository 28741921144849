import PcCategoryPanel from "~/components/common/category/CategoryPanel/PcCategoryPanel";
import SpCategoryPanel from "~/components/common/category/CategoryPanel/SpCategoryPanel";
import useIsSpMode from "~/lib/client/hooks/useIsSpMode";

type Props = {
  category: {
    id: number;
    name: string;
    quests: {
      id: number;
    }[];
    currentRaid?:
      | {
          stage: number;
          achievementPoint: number;
        }
      | null
      | undefined;
    accumulatedPoint: number;
    imageUrl?: string | null;
  };
  following?: boolean;
};

export type { Props };

const CategoryPanel = (props: Props) => {
  const isSpMode = useIsSpMode();

  return isSpMode ? (
    <SpCategoryPanel {...props} />
  ) : (
    <PcCategoryPanel {...props} />
  );
};

export default CategoryPanel;

import { css } from "@emotion/react";
import { Theme, useTheme } from "@mui/material";
import Link from "next/link";

import CategoryFollowButton from "~/components/common/category/CategoryFollowButton";
import type { Props } from "~/components/common/category/CategoryPanel/index";
import {
  engFont,
  fixedHeight,
  fixedWidth,
  flexColumn,
  flexRow,
} from "~/lib/client/design";
import "~/lib/monkeypatch";

const SpCategoryPanel = ({
  category: { id, name, quests, currentRaid, accumulatedPoint, imageUrl },
  following,
}: Props) => {
  const theme = useTheme();
  const raidProgressPercentage =
    currentRaid?.achievementPoint == undefined
      ? 0
      : Math.floor((accumulatedPoint / currentRaid.achievementPoint) * 100);

  return (
    <Link css={rootStyle(theme)} href={`/category/${id}`}>
      <div css={titleStyle(imageUrl)}>{name}</div>
      <div css={bottomStyle(theme)}>
        <div css={questAndFollowButtonStyle}>
          <div css={questCountStyle} className={engFont.className}>
            <span css={labelStyle}>Quest</span>
            <span css={valueStyle}>{quests.length}</span>
          </div>
          <CategoryFollowButton categoryId={id} following={following} />
        </div>
        <div css={projectContainerStyle}>
          <span css={labelStyle}>Project</span>
          <span css={valueStyle}>
            {(currentRaid?.stage ?? 0).ordinal()} Stage |{" "}
            {raidProgressPercentage ?? 0}%
          </span>
        </div>
      </div>
    </Link>
  );
};

export const size = {
  width: 180,
  height: 163,
};

const rootStyle = (theme: Theme) => css`
  ${flexColumn}
  ${fixedWidth(`${size.width}px`)}
  ${fixedHeight(`${size.height}px`)}
  border: 1px solid ${theme.palette.divider};
  border-radius: 1rem;
  background: ${theme.palette.background.default};
  box-sizing: border-box;
`;

const titleStyle = (backgroundImageUrl: string | null | undefined) => css`
  margin-top: auto;
  margin-bottom: auto;
  ${fixedHeight("calc(163px - 69px)")}
  font-size: 19px;
  font-weight: 600;
  padding: 1rem;
  display: flex;
  align-items: center;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.7)
    ),
    url(${backgroundImageUrl});
  background-size: cover;
  border-radius: 1rem 1rem 0 0;
`;

const bottomStyle = (theme: Theme) => css`
  ${flexColumn};
  ${fixedHeight("69px")};
  align-items: flex-start;
  justify-content: center;
  background: ${theme.palette.background.paper};
  padding-bottom: 0.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0 0 1rem 1rem;
  gap: 0.25rem;
`;

const questAndFollowButtonStyle = css`
  ${flexRow};
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
`;

const questCountStyle = css`
  ${flexRow};
  gap: 0.25rem;
  align-items: flex-end;
`;

const labelStyle = css`
  font-size: 12px;
  margin-right: 0.25rem;
`;

const valueStyle = css`
  font-size: 12px;
  font-weight: 700;
`;

const projectContainerStyle = css`
  ${flexRow};
  align-items: flex-end;
`;

export default SpCategoryPanel;

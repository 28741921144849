export {};

declare global {
  // eslint-disable-next-line unused-imports/no-unused-vars
  interface Array<T> {
    isEmpty(): boolean;

    indexById(): {
      [id: number]: T extends { id: number } ? T : never;
    };

    compact(): Exclude<T, null | undefined>[];

    shuffle(): T[];

    chunk(size: number): T[][];
  }

  interface Number {
    ordinal(capitalize?: boolean): string;
  }
}

Array.prototype.isEmpty = function () {
  return this.length === 0;
};

Array.prototype.indexById = function () {
  return this.reduce(function (map, obj) {
    map[obj.id] = obj;
    return map;
  }, {});
};

Array.prototype.compact = function <T>(this: (T | null | undefined)[]): T[] {
  return this.filter(
    (element): element is T => element !== null && element !== undefined
  );
};

Array.prototype.shuffle = function <T>(this: T[]): T[] {
  const array = this.slice();
  for (let i = array.length - 1; i > 0; i--) {
    const r = Math.floor(Math.random() * (i + 1));
    const tmp = array[i];
    array[i] = array[r];
    array[r] = tmp;
  }
  return array;
};

Array.prototype.chunk = function <T>(this: T[], size: number): T[][] {
  return this.reduce(
    (chunks, _, index) =>
      index % size === 0
        ? [...chunks, this.slice(index, index + size)]
        : chunks,
    [] as T[][]
  );
};

Number.prototype.ordinal = function (this: number, capitalize = false) {
  let num = Math.round(this);
  let numString = num.toString();

  // If the ten's place is 1, the suffix is always "th"
  // (10th, 11th, 12th, 13th, 14th, 111th, 112th, etc.)
  if (Math.floor(num / 10) % 10 === 1) {
    return numString + (capitalize ? "TH" : "th");
  }

  // Otherwise, the suffix depends on the one's place as follows
  // (1st, 2nd, 3rd, 4th, 21st, 22nd, etc.)
  switch (num % 10) {
    case 1:
      return numString + (capitalize ? "ST" : "st");
    case 2:
      return numString + (capitalize ? "ND" : "nd");
    case 3:
      return numString + (capitalize ? "RD" : "rd");
    default:
      return numString + (capitalize ? "TH" : "th");
  }
};

import { css } from "@emotion/react";
import { Theme, useTheme } from "@mui/material";
import { MouseEventHandler, useCallback } from "react";
import { match } from "ts-pattern";

import useFollowCategory from "~/lib/client/hooks/category-following/useFollowCategory";
import useIsCategoryFollowing from "~/lib/client/hooks/category-following/useIsCategoryFollowing";
import useIsSpMode, { SP_MODE_WIDTH } from "~/lib/client/hooks/useIsSpMode";

const CategoryFollowButton = ({
  categoryId,
  following: providedFollowing,
}: {
  categoryId: number;
  following?: boolean;
}) => {
  const { following, reload } = useIsCategoryFollowing(
    categoryId,
    providedFollowing
  );
  const label = match(following)
    .with(true, () => "Following")
    .with(false, () => "Follow")
    .with(undefined, () => null)
    .exhaustive();
  const theme = useTheme();
  const { followCategory, unfollowCategory } = useFollowCategory(categoryId);
  const handleClick: MouseEventHandler = useCallback(
    async (event) => {
      event.preventDefault();
      await match(following)
        .with(true, () => unfollowCategory())
        .with(false, () => followCategory())
        .with(undefined, () => alert("ログインしてください"))
        .exhaustive();
      await reload();
    },
    [following, reload, followCategory, unfollowCategory]
  );
  const isSpMode = useIsSpMode();

  return label ? (
    <div
      role={"button"}
      css={rootStyle(theme, !!following, !!isSpMode)}
      onClick={handleClick}
    >
      {label}
    </div>
  ) : null;
};

const rootStyle = (theme: Theme, following: boolean, isSpMode: boolean) => css`
  width: ${isSpMode ? "4.5rem" : "6rem"};
  height: ${isSpMode ? "20px" : "30px"};
  border: 1px solid ${theme.palette.primary.main};
  border-radius: 3.75rem;
  background: ${following
    ? theme.palette.primary.contrastText
    : theme.palette.primary.main};
  color: ${following
    ? theme.palette.primary.main
    : theme.palette.primary.contrastText};
  text-align: center;

  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  font-size: ${isSpMode ? "12px" : "16px"};

  @media (min-width: ${SP_MODE_WIDTH}px) {
    // SPでやると、ホバーが取れず色が残るため、PCのみにする
    :hover {
      cursor: pointer;
      background: ${theme.palette.primary.dark};
    }
  }
`;

export default CategoryFollowButton;

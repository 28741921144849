import { useGraphqlClient } from "~/lib/client/hooks/useGraphqlClient";
import { useAppContext } from "~/pages/_app";

const useFollowCategory = (categoryId: number) => {
  const client = useGraphqlClient();
  const { currentUser } = useAppContext();

  const followCategory = async () => {
    if (!client || !currentUser) {
      return;
    }

    await client.followCategory({
      categoryId,
    });
  };

  const unfollowCategory = async () => {
    if (!client || !currentUser) {
      return;
    }

    await client.unfollowCategory({
      categoryId,
    });
  };

  return { followCategory, unfollowCategory };
};

export default useFollowCategory;

import { useCallback, useEffect, useState } from "react";

import { useGraphqlClient } from "~/lib/client/hooks/useGraphqlClient";

const useIsCategoryFollowing = (id: number, providedFollowing?: boolean) => {
  const client = useGraphqlClient();
  const [following, setFollowing] = useState<boolean | undefined>(
    providedFollowing
  );
  const reload = useCallback(async () => {
    if (!client) {
      return;
    }

    const {
      category: { currentUserIsFollowing },
    } = await client.isFollowingCategory({
      categoryId: id,
    });
    setFollowing(currentUserIsFollowing);
  }, [client, id]);

  useEffect(() => {
    if (!client) {
      return;
    }

    // 初回ロード時に providedFollowing が undefined でなければ、 fetch しない
    if (typeof providedFollowing !== "undefined") {
      return;
    }

    (async () => {
      const {
        category: { currentUserIsFollowing },
      } = await client.isFollowingCategory({
        categoryId: id,
      });
      setFollowing(currentUserIsFollowing);
    })();
  }, [client, id, providedFollowing]);

  return { following, reload };
};

export default useIsCategoryFollowing;

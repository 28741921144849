import { css } from "@emotion/react";
import { Theme, useTheme } from "@mui/material";
import Link from "next/link";

import CategoryFollowButton from "~/components/common/category/CategoryFollowButton";
import { Props } from "~/components/common/category/CategoryPanel/index";
import {
  fixedHeight,
  fixedWidth,
  flexColumn,
  flexRow,
} from "~/lib/client/design";

const PcCategoryPanel = ({
  category: { id, name, quests, currentRaid, accumulatedPoint, imageUrl },
  following,
}: Props) => {
  const theme = useTheme();
  const raidProgressPercentage =
    currentRaid?.achievementPoint == undefined
      ? 0
      : Math.floor((accumulatedPoint / currentRaid.achievementPoint) * 100);

  return (
    <Link css={rootStyle(theme)} href={`/category/${id}`}>
      <div css={titleStyle(imageUrl)}>{name}</div>
      <div css={bottomStyle(theme)}>
        <div css={questAndRaidContainerStyle}>
          <div>
            <div css={labelStyle}>Quests</div>
            <div css={questCountStyle}>{quests.length}</div>
          </div>
          <div>
            <div css={labelStyle}>Project</div>
            <div css={raidStageAndProgressStyle}>
              {/*現状は Raid がないものがあるため*/}
              {(currentRaid?.stage ?? 0).ordinal()} Stage |{" "}
              {raidProgressPercentage ?? 0}%
            </div>
          </div>
        </div>
        <CategoryFollowButton categoryId={id} following={following} />
      </div>
    </Link>
  );
};

export const size = {
  width: 320,
  height: 200,
};

const rootStyle = (theme: Theme) => css`
  ${flexColumn}
  ${fixedWidth(`${size.width}px`)}
  ${fixedHeight(`${size.height}px`)}
  border: 1px solid ${theme.palette.divider};
  border-radius: 1rem;
  background: ${theme.palette.background.default};
  box-sizing: border-box;
`;

const titleStyle = (imageUrl: string | null | undefined) => css`
  margin-top: auto;
  margin-bottom: auto;
  ${fixedHeight("9rem")}
  font-size: 34px;
  font-weight: 600;
  padding: 1rem;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.7)
    ),
    url(${imageUrl});
  background-size: cover;
  border-radius: 1rem 1rem 0 0;
`;

const bottomStyle = (theme: Theme) => css`
  ${flexRow};
  ${fixedHeight("3.5rem")};
  align-items: flex-start;
  justify-content: space-between;
  background: ${theme.palette.background.paper};
  padding-top: 0.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0 0 1rem 1rem;
`;

const questAndRaidContainerStyle = css`
  ${flexRow};
  gap: 0.5rem;
`;

const labelStyle = css`
  font-size: 12px;
`;

const questCountStyle = css`
  font-size: 16px;
  font-weight: 700;
`;

const raidStageAndProgressStyle = css`
  ${flexRow};
  gap: 0.25rem;
  font-size: 16px;
  font-weight: 700;
`;

export default PcCategoryPanel;

// ref: https://dev.to/reedbarger/how-to-create-a-custom-usedevicedetect-react-hook-56l1
import { useEffect, useState } from "react";

export default function useDeviceDetect() {
  const [userAgent, setUserAgent] = useState<string | undefined>(undefined);

  useEffect(() => {
    const userAgent =
      typeof window.navigator === "undefined" ? undefined : navigator.userAgent;
    setUserAgent(userAgent);
  }, []);

  return { userAgent };
}
